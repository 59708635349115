
@media screen and (max-width: 400px) {
    .tableContainer {
        max-height: 60% !important;
    }
}
@media screen and (max-width: 600px) {
    .tableContainer {
        max-height: 65% !important;
    }
}

table {
    text-align: center;
    border-spacing: 0px;
    font-size: 0.9em;
    position: relative;
    width: 95%;
}

tr:hover {
    background-color: var(--disabledColor);
}

tr img {
    width: 1.2em;
    height: 1.2em;
    margin: 0px 0.3em;
}
tr img:hover {
    cursor: pointer;
    transform: scale(1.1);
}

th {
    padding: 0.5em;
    font-size: 1em;
    background-color: rgba(128, 193, 57, 1);
    text-transform: uppercase;
}
th:hover {
    cursor: ns-resize;
    text-decoration: underline !important;
}

td {
    font-size: 0.9em;
    padding: 0px 0.3em;
}