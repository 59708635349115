@import "animations.css";
@import "confirm.css";
@import "fields.css";
@import "popup.css";
@import "table.css";

@media screen and (max-width: 400px) {
    :root {
        --fontSize: 13px !important;
        --boxShadow: 0px 0px 5px 0px #000000 !important;  
    }
}
@media screen and (max-width: 600px) {
    .menu {
        margin: 0.5em 0px !important;
    }
    .responsiveLine {
        flex-wrap: wrap !important;
    }
    .eventoLine img {
        width: 2em !important;
    }
    .eventoLine line {
        flex-wrap: wrap !important;
    }
    .explorer {
        columns: 1 !important;
        width: 100% !important;
    }
    .titolo {
        display: none !important;
    }
    .titoloMobile {
        display: flex !important;
    }
}
@media screen and (min-width: 800px) and (max-width: 1200px) {
    :root {
        --fontSize: 20px !important;
        --boxShadow: 0px 0px 7px 0px #000000 !important;  
    }
}
@media screen and (min-width: 600px) and (max-width: 800px) {
    :root {
        --fontSize: 18px !important;
        --boxShadow: 0px 0px 7px 0px #000000 !important;  
    }
}
@media screen and (min-width: 400px) and (max-width: 600px) {
    :root {
        --fontSize: 15px !important;
        --boxShadow: 0px 0px 7px 0px #000000 !important;  
    }
}
@media screen and (max-width: 1400px) {
    .logoImg {
        position: unset !important;
        margin-top: 0.5em;
    }
}


@font-face {
    font-family: "Barlow";
    src: url("./fonts/BarlowCondensed-Regular.ttf");
}

body {
    background-color: var(--bgColor);
    font-family: "Barlow";
    font-size: var(--fontSize);
    margin: unset;
}

.root {
    box-sizing: unset;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100vh;
    width: 100vw;
}
.root::-webkit-scrollbar {
    display: none;
}

h1 {
    margin: unset;
    text-align: center;
    width: 100%;
    padding: unset;
}
h2 {
    margin: unset;
    font-size: 1.5em;
    text-align: center;
    text-transform: uppercase;
    padding: unset;
}
h3 {
    margin: unset;
    font-size: 1em;
    text-align: center;
    padding: unset;
}
s {
    text-decoration: unset;
    color: red;
}
line {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    padding: 0.6em;
}
line img {
    width: 3em;
}

.copyright {
    position: fixed;
    bottom: 0.5em;
    right: 0.5em;
    font-size: 0.8em;    
}

.border {
    border: 0.1vw solid #000;
    border-radius: 1em;
    width: 95%;
    margin-bottom: 1em; 
    flex-wrap: wrap;
}

.elementContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 95%;
    padding: 0.5em;
    box-shadow: var(--boxShadow);
    border-radius: 1em;
    height: -webkit-fill-available;
    max-height: 80vh;
    overflow-x: hidden;
    overflow-y: auto;
}

.titolo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
}
.titoloMobile {
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    font-size: 0.9em;
    margin-bottom: 0.4em;
}

.clickImage:hover {
    cursor: pointer;
    transform: scale(1.2);
}
.disabledImage:hover {
    cursor: not-allowed;
}

.hoverText {
    position: fixed !important;
    padding: 0.5em !important;
    background: var(--bgColor) !important;
    border-radius: 0.5em !important;
    word-wrap: break-word;
    z-index: 9;
    border: 0.1em solid #000;
    min-width: fit-content;
}

.fieldActive {
    border-width: medium;
    box-shadow: 0px 0px 10px 0px #4d813a;
    border-color: #4d813a;
}

.fullSlide { 
    width: 100vw;
    height: 100vh;
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;
    text-align: center;
    background-color: var(--bgColor);
} 

#hovers {
    position: absolute;
    left: 35%;
    top: 5%;
    z-index: 9;
    width: 30%;
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: center;
    background: transparent;
    max-height: 90vh;
}

.hoverDiv {
    width: 100%;
    height: 3em;
    font-size: 1.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 0.7em;
    color: #FFF;
    margin-bottom: 0.5em;
    text-align: center;
    padding: 0.1em 0.5em;
}
.hoverDiv:hover {
    cursor: pointer;
}

.slide {
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    overflow: hidden
}

.intestazione {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    width: 95%;
    padding: unset;
    font-size: 0.8em;
}
.intestazione h1 {
    padding: unset;
    text-transform: uppercase;
}
.intestazione line {
    width: unset;
    padding: unset;
}
.intestazione img {
    width: 3em;
}


.sliderShow {
    width: 100%;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    text-align: center;
}

.slideView {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-radius: 0.5em;
    box-shadow: var(--boxShadow);
    margin: 0.5em;
    padding: 0.5em;
    width: 17em;
    height: 9em;
}
.slideView h2 {
    width: 100%;
    overflow-x: hidden;
    padding: unset;
    font-size: 1em;
    position: relative;
}
.slideView h3 {
    width: 100%;
    overflow-x: hidden;
    padding: unset;
    position: relative;
    font-size: 0.9em;
}
.slideView span {
    font-size: 0.8em;
}
.slideView img {
    overflow: auto;
    width: 2.5em;
}
.slideView line {
    font-size: 0.45em;
    justify-content: space-evenly;
    position: relative;
}
.slideView line button {
    background: var(--bgColor);
}

.file {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0.3em 0px;
    padding: unset;
    height: 2em;
}
.file img {
    width: 2em;
}
.file img:hover {
    cursor: pointer;
    transform: scale(1.1);
}
.file h3 {
    text-align: left;
    font-size: 0.8em;
    padding: 0.5em;
}
.file h3:hover {
    cursor: pointer;
    text-decoration: underline;
}

.explorer {
    display: table-column;
    columns: 2;
    table-layout: auto;
    width: 90%;
    overflow-y: auto;
    overflow-x: hidden;
}

.percorso {
    display: flex;
    justify-content: center;
    align-items: center;
}
.percorso span {
    margin: 0px 0.2em;
}
.percorso span:hover {
    cursor: pointer;
    text-decoration: underline;
}

.menu {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.6em;
    width: 95%;
}
.menu img {
    width: 3em;
}
.menu::-webkit-scrollbar {
    display: none;
}
.menu .sliderShow::-webkit-scrollbar {
    display: none;
}

.logoImg {
    position: fixed;
    width: 10em;
    left: 2em;
    top: 0.5em;
}

.description {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0.5em;
    font-size: 0.9em;
}

.blockDiv {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 0.5em;
    box-shadow: var(--boxShadow);
    padding: 0.5em;
    margin: 0.5em;
    font-size: 0.9em;
    width: 5em;
    height: 5em;
}
.blockDiv span {
    font-size: 0.7em;
    width: 100%;
    padding: unset;
}
.blockDiv line {
    padding: unset;
    font-size: 0.7em;
}

.columnList {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 53vh;
    overflow-y: auto;
    overflow-x: hidden;
}
.columnList h2 {
    margin-bottom: 0.5em;
    font-size: 1.3em;
}

.rowLine {
    padding: unset;
    justify-content: flex-start;
    padding: 0px 0.5em;
    border-radius: 0.5em;
    width: 95%;
    margin: 0.1em;
}
.rowLine:hover {
    background-color: var(--btColor);
}
.rowLine img {
    width: 2em;
}

.avatarDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.2em;
    border: 0.07em solid #000;
    border-radius: 1em;
    width: 6em;
    margin: 0px 0.5em;
}
.avatarDiv img {   
    width: 40%;
}
.avatarDiv span {
    width: 90%;
    overflow-x: scroll;
    overflow-y: hidden;
    font-size: 0.8em;
    text-align: center;
    padding: 0.2em;
}
.avatarDiv span::-webkit-scrollbar {
    display: none;
}

.fileDiv {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0.2em;
    border: 0.07em solid #000;
    border-radius: 1em;
    width: 6em;
    min-height: 6em;
    margin: 0.5em;
}
.fileDiv img {   
    width: 50%;
    margin-bottom: 0.3em;
    cursor: pointer;
}
.fileDiv img:hover {
    transform: scale(1.05);
}
.fileDiv span {
    width: 90%;
    overflow: auto;
    font-size: 0.8em;
    text-align: center;
    padding: 0.2em;
    word-wrap: break-word;
    height: 2.5em;
}
.fileDiv span::-webkit-scrollbar {
    display: none;
}

.tagDiv {
    display: flex;
    background-color: var(--disabledColor);
    padding: 0.2em;
    border-radius: 0.4em;
    margin: 0.2em;
}
.tagDiv img {
    width: 1.3em;
    margin-right: 0.3em;
}
.tagDiv span {
    padding-right: 0.5em;
}

.cliccableDiv:hover {
    cursor: pointer;
    transform: scale(1.1);
}