
@media screen and (max-width: 800px) {
    .inLine {
        flex-direction: column !important;
    }
}

input {
    font-family: inherit;
    font-size: inherit;
    text-align: center;
    padding: 0.4em;
    border-radius: 0.5em;
    background-color: white;
    border: 0.1em solid black;
    color: black;
    letter-spacing: 0.1em;
}
input:disabled {
    background-color: var(--disabledColor);
    border: none;
}
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; 
}

select {
    font-family: inherit;
    font-size: inherit;
    text-align: center;
    padding: 0.4em;
    border-radius: 0.5em;
    background-color: white;
    border: 0.1em solid black;
    color: black;
    min-width: 10em;
}
select:disabled {
    background: var(--disabledColor);
    border: none;
}

button {
    font-family: inherit;
    text-align: center;
    padding: 0.6em 0.8em;
    font-size: 0.8em;
    background: var(--btColor);
    border: 0.1em solid black;
    border-radius: 0.5em;
    color: black;
}
button:hover {
    cursor: pointer;
    transform: scale(1.1);
}
button:disabled {
    background: var(--disabledColor);
}
button:disabled:hover {
    cursor: not-allowed;
    transform: none;
}

textarea {
    border-radius: 1em;
    padding: 0.8em;
    font-size: 0.8em;
    border: 0.1em solid #000;
    color: #000;
    font-family: inherit;
    width: -webkit-fill-available;
    height: 10em;
    text-align: center;
}
textarea::placeholder {
    text-align: center;
}

input[type="range"] {
    width: 60%;
    height: 1em;
    appearance: none;
    --webkit-appearance: none;
    background: var(--disabledColor);
    border-radius: 1em;
    border: none;
}
input[type="range"]::-webkit-slider-thumb {
    appearance: none;
    --webkit-appearance: none;
    height: 4em;
    width: 2em;
    background: var(--btColorFull);
    border-radius: 1em;
    cursor: pointer;
}
input[type="color"] {
    width: 2em;
    height: 2em;
    padding: 0.1em;
}

.fileButton {
    background: var(--disabledColor);
    margin-right: 0.5em;
    font-size: 0.6em;
    padding: 1em 1.5em;
}

.inputContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.5em;
}
.inputContainer span {
    text-transform: uppercase;
    padding: 0.3em;
    text-align: center;
}

.inLine {
    flex-direction: row;
}
.inLine span {
    margin: 0px 0.5em;
}

.booleanInput {
    flex-direction: row;
}
.selectBox {
    border: 0.1em solid black;
    border-radius: 0.4em;
    width: 1em;
    height: 1em;
    margin: 0px 0.4em;
}
.selectBox:hover {
    cursor: pointer;
}
.selected {
    background-color: var(--btColor);
}
.booleanShow {    
    background-color: var(--disabledColor);
    border-radius: 0.5em;
    text-align: center;
    width: -webkit-fill-available; 
}
.booleanShow:hover {
    cursor: pointer;
}
.cliccableSpan:hover {
    text-decoration: underline;
    cursor: pointer;
}

.numberInputContainer {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.numberInputContainer input {
    width: 8em;
    margin: 0px 0.5em;
}
.numberInputContainer button {
    padding: 0.5em 1em;
    font-size: 0.7em;
}

.hiddenInput {
    background: transparent;
    border: none;
    width: 5em !important;
}

.loadingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1vw;    
}
.loadingContainer img {
    width: 80%;
    max-width: 10vw;
}

.timeInputs {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.passwordStrength {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 0.6em;
    margin: 1em 0px;
}
.passwordStrength span {
    padding: unset;
    text-transform: unset;
}
.passed {
    color: green;
}

.inLine .passwordStrength {
    margin: 1em 2em;
}

.loaderContainer {
    position: absolute;
    left: 30%;
    top: 20%;
    width: 40%;
    text-align: center;
}
.loaderContainer img {
    position: relative;
}
.loaderContainer h3 {
    position: relative;
    font-size: 1.5em;
    margin: 0.5em 0px;
}
.loaderContainer ul {
    width: fit-content;
    margin: auto;
    text-align: left;
}

.btnImage {
    padding: 0.6em 0.8em;
    font-size: 0.8em;
    background: var(--btColor);
    border: 0.1em solid black;
    border-radius: 0.5em;
}
.btnImage:hover {
    cursor: pointer;
    transform: scale(1.1);
}

.scrollTop {
    position: absolute;
    visibility: hidden;
    background-color: var(--btColor);
    padding: 0.2em;
    border-radius: 0.5em;
    border: 0.05em solid black;
    width: 3em !important;
    cursor: pointer;
    transform: translate(-5em, -4em);
}
